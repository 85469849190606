import React, {useState, useEffect, useRef } from "react";
import {SetMetaData} from './../../helper/page-title';
import moment from 'moment';
// import momentTZ from 'moment-timezone';
import * as XLSX from 'xlsx';
import ValidationHelper from "../../helper/validation";
import {post_api_call,
        error_api_call,
        get_api_call_main,
        get_api_call
      } from './../../actions/action-creators/common';
import { alertResponseMessage } from "../../actions/action-creators/response";
//Modal
import ModalMaster from "../../modal/master";
import ModalMasterDetail from "../../modal/master-detail";
import FormDataHub from "../../data/formData/students";
import FilterFloatMaster from "../../components/form/filter-float";
import {
  studentTypeOpp,
  portCompleOpp,
  portExpiryOpp,
  activeStatusOpp,
  portSectionOpp,
  portSecTitle,
  filterOperator
} from "../../data/formData/option";
import { calculateLeftDays } from "../../components/form/date";
import { ExpiryDays } from "../../components/ui/date";

const {
    studentFilter,
    studentFilterPrin,
    studentFilterTeach,
    fieldNewStake,
    fieldValuesStake,
    fieldNewTeacher,
    filterInitial,
    // filterInitialTeach,
    // fieldValuesTeacher,
    fieldValidationStake,
    fieldValidationTeacher,
    fieldStakeView,
    fieldStakeViewTeach,
    fieldEditStake,
    fieldEditTeacher,
    fieldValidEditTeacher,
    fieldValidEditStake
} = FormDataHub;
const masterTitle = {
    new: 'New Student Entry',
    edit: 'Edit Student Entry',
    detail: 'Student Details'
};
const filterFieldType = {
  id: "string",
  school_id: "array",
  class_id: "array",
  name: "string",
  email: "string",
  mobile_number: "string",
  country_id: "array",
  state_id: "array",
  city_id: "array",
  member_type: "object",
  completion_id: "object",
  validity_id: "object",
  competition_id: "object",
  port_pending_id: "array",
  port_filled_id: "array",
  active_status_id: "object",
  created_at: "string",
  mobile_code_id: "object",
  port_pending_oper: "object",
  port_filled_oper: "object"
};

function StudentList(props){
    SetMetaData("Manage Students", "", "");
    const [isLoading, setIsLoading] = useState(true);
    const [modalLoader, setModalLoader] = useState({edit: false, detail: false});
    const [modalToggle, setModalToggle] = useState({new: false,
                                                    edit: false,
                                                    detail: false});
    const [masterTableData, setMasterTableData] = useState([]);
    const [masterFieldData, setMasterFieldData] = useState([]);
    const [filterTableData, setFilterTableData] = useState([...studentFilter]);
    const [filterData, setFilterData] = useState({...filterInitial
                                                // id: "",
                                                // school_id: "",
                                                // class_id: "",
                                                // name: "",
                                                // email: "",
                                                // mobile_number: "",
                                                // country_id: "",
                                                // state_id: "",
                                                // city_id: "",
                                                // member_type: "",
                                                // completion_id: "",
                                                // validity_id: "",
                                                // competition_id: "",
                                                // com_pending_id: "",
                                                // com_filled_id: "",
                                                // active_status_id: "",
                                                // created_at: ""
                                              });
    // const [newEntryFields, setNewEntryFields] = useState();
    const [activeEditForm, setActiveForm] = useState("");
    // const [formDataMaster, setFormMasterData] = useState([]);
    const [errorData, setErrorData] = useState({new: [], edit: []});
    const [formNewData, setNewFormData] = useState({});
    const [formEditData, setEditFormData] = useState({});
    const [formViewData, setViewFormData] = useState([]);
    const [formUpdatedData, setUpdatedFormData] = useState({});
    const [isProcess, setIsProcess] = useState({new: false, edit: false, filter: false, download: false});
    //Single Details
    const [detailedOppData, setDetailedOppData] = useState({});
    // const [isBtnDisabled, setBtnDisabled] = useState({new: true, edit: true});
    //Options Data
    const [oppSchoolOpt, setSchoolOpt] = useState([]);
    const [oppClassOpt, setClassOpt] = useState([]);
    const [oppCountryOpt, setCountryOpt] = useState([]);
    const [oppStateOpt, setStateOpt] = useState([]);
    const [oppCityOpt, setCityOpt] = useState([]);
    const [oppComptOpt, setComptOpt] = useState([]);
    const [filenameExcel, setNameExcel] = useState("");
    const tableRef = useRef();
    //Total
    const [totalResult, setTotalResult] = useState(0);
    const [totalStudent, setTotalStudent] = useState(0);
    const [isFilter, setIsFilter] = useState(0);
    //Get Data for Dropdown
    const fetchStudentList = async(event="", form_type="") => {
      try{
        setIsLoading(true);
        if(event){
          event.preventDefault();
        }
        if(form_type === 'filter'){
          setIsProcess({...isProcess, [form_type]: true});
        }
        const filterDetails = getFilterData();
        console.log(filterDetails, 'filterDetailsfilterDetailsfilterDetails')
        // if(Object.keys(filterData).length){
        //   Object.keys(filterData).map((filter) => {
        //     if(filterFieldType[filter] === 'array'){
        //       if(filterData[filter] && filterData[filter].length){
        //         var filterIds = [];
        //         filterData[filter].map((f_data) => {
        //           filterIds.push(f_data.id);
        //         });
        //         filterDetails[filter] = filterIds;
        //       }
        //     }else if(filterFieldType[filter] === 'object'){
        //       console.log(filter)
        //       if(filter !== 'mobile_code_id'
        //           && filterData[filter] 
        //           && typeof filterData[filter].id !== "undefined"
        //       ){
        //         console.log('yesss')
        //         filterDetails[filter] = filterData[filter].id;
        //       }
        //     }else{
        //       if(filter === "mobile_number"
        //         && filterData['mobile_number']
        //       ){
        //         if(typeof filterData['mobile_code_id'] !== "undefined"
        //           && typeof filterData['mobile_code_id'].id !== "undefined"
        //         ){
        //           filterDetails['mobile_code_id'] = filterData['mobile_code_id'].id;
        //         }else{
        //           filterDetails['mobile_code_id'] = 101;
        //         }
        //       }
        //       if(filterData[filter]){
        //         filterDetails[filter] = filterData[filter];
        //       }
        //     }
        //   });
        // }
        const listApiData = await post_api_call('students',
                                                {limit: 500,
                                                page: 1,
                                                filter: 
                                                  {...filterDetails},
                                                });
        if(listApiData.status === 200){
          setMasterTableData(listApiData.data);
          setTotalResult(listApiData.total);
          if(form_type !== 'filter'
            && listApiData.totalStudent !== 0
          ){
            setTotalStudent(listApiData.totalStudent);
          }
          setIsFilter(filterDetails && Object.keys(filterDetails).length ? 1 : 0);
        }
        if(form_type === 'filter'){
          setIsProcess({...isProcess, [form_type]: false});
          const timer = setTimeout(() => {
            // scrollToTable();
            tableRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 300);
        }
        setIsLoading(false);
      }catch(e){
        console.log(e)
        error_api_call({error: `admin|student|list|fetchStudentList|${e}`});
        if(form_type === 'filter'){
          setIsProcess({...isProcess, [form_type]: false});
        }
        // alertResponseMessage({alert_type: 2, message: "Error Fetching Data"});
      }
    }
    //Get Data from APIs
    const fetchSelectList = async(type, url, api_type="") => {
      try{
          const selectApiData = api_type !== 'api' ? await get_api_call_main(url, {}) : await get_api_call(url, {});
          var data = [];
          if(selectApiData.status === 200){
              data = selectApiData.data;
              switch (type) {
                  case 'schools':
                    setSchoolOpt([...data]);
                    break;
                  case 'classes':
                    setClassOpt(data);
                    break;
                  case 'country':
                    setCountryOpt(data);
                    break;
                  case 'state':
                    setStateOpt(data);
                    break;
                  case 'city':
                    setCityOpt(data);
                    break;
                  case 'competition':
                    setComptOpt(data);
                    break;

                    

                    
                  default: 
              }
          }
          return {
              data
          };
      }catch(e){
        error_api_call({error: `admin|student|list|fetchSelectList|${e}`});
        return {data: []};
      }
    }
    useEffect(() => {
    //   var filterTblData = [...studentFilterTeach];
    //   if(props.userRoleIds.includes(2)){//SA
    //     filterTblData = [...studentFilter];
    //   }else if(props.userRoleIds.includes(5)){//Principal
    //     filterTblData = [...studentFilterPrin];
    //   }
    //   console.log(props.userRoleIds, 'props.userRoleIdsprops.userRoleIds');
    //   setFilterTableData([...filterTblData]);
      //Initial API Data
      fetchStudentList();
      fetchSelectList('schools', 'data/schools', 'api');
      fetchSelectList('classes', 'data/classes', 'api');
      //SA
      // if(!oppCountryOpt.length){
        fetchSelectList('country', 'countries');
      // }
      // if(!oppStateOpt.length){
        fetchSelectList('state', 'states');
      // }
      fetchSelectList('city', 'cities');
      fetchSelectList('competition', 'competitions?fetch_type=only_comp');
    }, []);

    useEffect(() => {
      var filterTblData = [...studentFilterTeach];
      if(props.userRoleIds.includes(2)){//SA
        filterTblData = [...studentFilter];
        // if(!oppCountryOpt.length){
        //   fetchSelectList('country', 'countries');
        // }
        // if(!oppStateOpt.length){
        //   fetchSelectList('state', 'states');
        // }
      }else if(props.userRoleIds.includes(5)){//Principal
        filterTblData = [...studentFilterPrin];
      }
      console.log(props.userRoleIds, 'props.userRoleIdsprops.userRoleIds');
      setFilterTableData([...filterTblData]);
    }, [props.userRoleIds]);
    //Display Filled Section
    // const filledPortSecView = (filled_sec) => {
    //   try{
    //     const secArray = filled_sec.split(',');
    //     console.log(secArray, 'secArray')
    //   }catch(e){
    //     return '';
    //   }
    // }
    //View Data
    const loadViewModal = async(e, load_type, user_id, role_id) => {
      e.preventDefault();
      try{
        //Page Loading
        toggleModal(e, load_type, true, '', load_type, role_id);
        setModalLoader({...modalLoader, [load_type]: true});
        const viewApiData = await post_api_call(`student/details/${user_id}`, {role_id});
        if(viewApiData.status === 200){
            if(load_type === 'detail'){
            //   if(viewApiData.data.fee_id !== 6
            //     && viewApiData.data.amount
            //   ){
            //     viewApiData.data.amount = `${viewApiData.data.currency_type === 1 ? '₹' : '$'} ${viewApiData.data.amount}`;
            //   }
              setViewFormData(role_id === 3 ? fieldStakeViewTeach : fieldStakeView);
              setDetailedOppData(viewApiData.data);
            }else{
              setUpdatedFormData({});
              //Opp Type
              var apiDataEdit = {...fieldValuesStake, ...viewApiData.data};
              if(role_id === 3){
                if(viewApiData.data.admin_class_details.length > 0){
                    var classIds = [];
                    viewApiData.data.admin_class_details.forEach(d_class => {
                        classIds.push({id: d_class.class_id, class_name: d_class.class_name});
                    });
                    apiDataEdit.class_id = classIds;
                }
              }
              
              setEditFormData({...apiDataEdit});
            }
        //   setOpenModal({true});
          setModalLoader({...modalLoader, [load_type]: false});
        }else{
          alertResponseMessage({alert_type: 2, message: "Error Loading Data"});
        }
      }catch(error){
        error_api_call({error: `admin|student|list|loadViewModal|${error}`});
        alertResponseMessage({alert_type: 2, message: "Error Loading Data"});
      }
    }
    //Approve Toggle
    const approveToggle = async(e, type, is_active, user_id, role_id) => {
        e.preventDefault();
        try{
          // status_change
          if (window.confirm(`${is_active === 1 ? 'Inactive' : 'Activate'} Student?`)){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = true;
            }else{
                e.target.disabled = true;
            }
            const statusApiData = await post_api_call(`student/status-change`, {id: user_id, role_id, is_active: is_active === 2 ? 1 : 2});
            if(statusApiData.status === 200){
                fetchStudentList();
                alertResponseMessage({alert_type: 1, message: `Student ${is_active === 1 ? 'Deactivated' : 'Activated'} Successfully!`});
            }
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          }
        }catch(e){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          error_api_call({error: `admin|student|list|approveToggle|${e}`});
        }
    }
     //Update Input
    const updateInput = (form_type, state_var_type, input_type, field, value, field_type="", form_field="", index=0) => {
      try{
        console.log(form_type, input_type, field, value)
        if(form_type === 'download'){
          setNameExcel(value);
          return;
        }
          if(form_type !== 'filter'
            && errorData[form_type].length
          ){
            setErrorData({...errorData, [form_type]: []});
          }

          if(field === 'mobile_number'){
              if(value.length < 16){
                  value = value.replace(/[^0-9]/g, '');
              }else{
                  return false;
              }
          }else if(input_type === 'input'
              || input_type === 'textarea'
          ){
              // value = value.replace(/ /g, '');
              value = value.trimStart();
              // value = value.replace(/  /g, '');
              if(field === 'first_name'
                  || field === 'last_name'
              ){
                  value = value.replace(/[^a-zA-Z. ]/g, '')
                    .replace('  ', ' ');
              }
          }
          var updatedFormDetails = state_var_type === 'array' 
                            ? [] 
                            : state_var_type === 'object'
                                ? {}
                                : '';
          if(field_type === 'array'){
              // const fieldData = form_type === 'new' ?  : {...formEditData};
              var fieldData = {};
              // setEditFormData({...fieldData});
              switch (form_type) {
                case 'new':
                  fieldData = {...formNewData};
                    break;
                case 'edit':
                  fieldData = {...formEditData}
                    break;
                case 'filter':
                  fieldData = {...filterData}
                    break;
                default: 
                    break;
              }
              fieldData[form_field][index][field] = value;
              updatedFormDetails = {...fieldData};
          }else{
            // updatedFormDetails = form_type === 'new' ? {...formNewData} : {...formEditData};
            switch (form_type) {
              case 'new':
                updatedFormDetails = {...formNewData};
                  break;
              case 'edit':
                updatedFormDetails = {...formEditData}
                  break;
              case 'filter':
                updatedFormDetails = {...filterData}
                console.log('ttttttttttttttttt', updatedFormDetails)
                  break;
              default: 
                  break;
            }
            updatedFormDetails[field] = value;
              // setEditFormData({
              //     ...formEditData,
              //     [field]: value
              // });
          }
          // if(field === 'show_image'){
          //   updatedFormDetails.show_image = true;
          // }
          switch (form_type) {
            case 'new':
                setNewFormData(updatedFormDetails);
                if(field === 'role_id'){
                    if(value.id === 3){//Teacher
                        setMasterFieldData([{...masterFieldData[0], fields: [...fieldNewTeacher]}]);
                    }else{
                        setMasterFieldData([{...masterFieldData[0], fields: [...fieldNewStake]}]);
                    }
                }
                break;
            case 'edit':
                setEditFormData(updatedFormDetails);
                setUpdatedFormData({...formUpdatedData, [field]: value});
                break;
            case 'filter':
                setFilterData(updatedFormDetails);
                console.log('filter', filterData);
                console.log('updatedFormDetails', updatedFormDetails);
                break;
            default: 
                break;
          }
      }catch(e){
        console.log(e)
      }
    }
    // Toggle Modal
    const toggleModal = (e, modal_type, toggleState, index='', form_type='', role_id='') => {
        try{
            if(toggleState){
                e.preventDefault();
            }
            if(toggleState){
                // var data = {};
                // var heading = '';
                var legendTitle = 'Details';
                switch (form_type) {
                    case 'new':
                        // heading = 'New Hub Details',
                        oppSchoolOpt.length > 1 ? setNewFormData({...fieldValuesStake}) : setNewFormData({...fieldValuesStake, school_id: oppSchoolOpt[0]});
                        setMasterFieldData([{form_type: 'new', label: legendTitle, fields: [...fieldNewStake]}]);
                        break;
                    case 'edit':
                        // heading = 'Edit Hub Details',
                        // data = {...formDataMaster[index]};
                        // setEditFormData({...data});
                        setMasterFieldData([{form_type: 'edit', label: legendTitle, fields: role_id !== 3 ? [...fieldEditStake] : [...fieldEditTeacher]}]);
                        break;
                    // case 'detail':
                    //     // heading = 'Hub Details',
                    //     data = {...formDataMaster[index]};
                    //     break;
                    default: 
                        break;
                }
                setErrorData({...errorData, [form_type]: []});
                setActiveForm(form_type);
            }
            setModalToggle({...modalToggle, [modal_type]: toggleState});
            // if((form_type === 'new'
            //     || form_type === 'edit'
            // ) && oppSchoolOpt.length === 0){
            // //   fetchSelectList('schools', 'schools');
            //   fetchSelectList('schools', 'data/schools', 'api');
            //   fetchSelectList('classes', 'classes');
            //   fetchSelectList('role', 'data/role', 'api');
            // }
        }catch(error){
            console.log(error)
        }
    }
    //Submit Details
    const updateStake = async(e, form_type='') => {
      try{
          e.preventDefault();
          setErrorData({...errorData, [form_type]: []});
          setIsProcess({...isProcess, [form_type]: true});
          var errorDetails = {
            new: [],
            edit: []
          };
          var apiFormData = new FormData();
          //Validation
          var validation = [];
          var inputData = {};
          var validationRules = {};
          switch (form_type) {
              case 'new':
                inputData = {...formNewData};
                if(formNewData.role_id && formNewData.role_id.id === 3){//Teacher
                    validationRules = fieldValidationTeacher;
                }else{
                    validationRules = fieldValidationStake;
                }
                break;
              case 'edit':
                inputData = {...formEditData};
                console.log(inputData, 'inputDatainputDatainputDatainputDatainputDatainputDatainputData')
                if(inputData.role_id === 3){//Teacher
                    console.log(fieldValidEditTeacher, 'fieldValidEditTeacher')
                    validationRules = fieldValidEditTeacher;
                }else{
                    validationRules = fieldValidEditStake;
                }
                break;
              default:
          }
          console.log(inputData, 'inputDatainputDatainputData')
          validation = ValidationHelper.validation(inputData, validationRules).error;
          if(validation.length){
            errorDetails[form_type] = validation;
          }
          
          if(errorDetails[form_type].length === 0){
            var fields = ['first_name',
                        'last_name',
                        // 'role_id',
                        'email',
                        // 'school_id', 
                        'password',
                        // 'class_id'
                    ];
            if(form_type === 'edit'){
              inputData = {...formUpdatedData};
              fields = ['first_name',
                        'last_name',
                        'password'];
            }
            fields.forEach(field => {
              if(form_type === 'new'
                || (form_type === 'edit'
                    && typeof inputData[field] !== "undefined")
              ){
                apiFormData.append(field, inputData[field]);
              }
            });
            if(form_type !== 'edit'){
                apiFormData.append(`school_id`, inputData.school_id.id);
                apiFormData.append(`role_id`, inputData.role_id.id);
            }
            if((form_type === 'new'
                && formNewData.role_id
                && formNewData.role_id.id === 3)
                || (form_type === 'edit')
            ){
                //Class Id
                if(inputData.class_id
                    && inputData.class_id.length
                ){
                    inputData.class_id.forEach(value => {
                        apiFormData.append(`class_id[]`, value.id);
                    });
                }
            }
          }
          if(errorDetails[form_type].length > 0){
              setErrorData({...errorData, [form_type]: errorDetails[form_type]});
              setIsProcess({...isProcess, [form_type]: false});
              // setBtnDisabled({...isBtnDisabled, [form_type]: true});
          }else{
              //Send Data to Server
              var api = 'create';
              if(form_type === 'edit'){
                api = 'update';
                if(Object.keys(formUpdatedData).length === 0){
                  alertResponseMessage({alert_type: 3, message: "Nothing to Update"});
                  setIsProcess({...isProcess, [form_type]: false});
                  toggleModal('', form_type, false);
                  return;
                }else{
                  apiFormData.append(`user_id`, formEditData.id);
                }
              }              
              const updateFData = await post_api_call(`student/${api}`, apiFormData);
              if(updateFData.status === 200){
                  alertResponseMessage({alert_type: 1,
                                        message: form_type === 'new'
                                                ? "Student Created Successfully"
                                                : "Information Updated Successfully"});
                  // setBtnDisabled({...isBtnDisabled, [form_type]: true});
                  setIsProcess({...isProcess, [form_type]: false});
                  //Close Modal
                  toggleModal('', form_type, false);
                  setIsLoading(true);
                  fetchStudentList();
              }else{
                  setIsProcess({...isProcess, [form_type]: false});
                  if(typeof updateFData.message !== "undefined"){
                      setErrorData({...errorData, [form_type]: [updateFData.message]});
                  }
              }
          }
          
      }catch(e){
          console.log(e);
          setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
          setIsProcess({...isProcess, [form_type]: false});
          error_api_call({error: `admin|student|list|updateStake|${e}`});
      }
    }
    //Get Filtered Data
    const getFilterData = () => {
      try{
        var filterDetails = {};
        // console.log(filterData, 'filterDatafilterDatafilterData')
        if(Object.keys(filterData).length){
          Object.keys(filterData).forEach((filter) => {
            if(filterFieldType[filter] === 'array'){
              if(filterData[filter] && filterData[filter].length){
                var filterIds = [];
                filterData[filter].forEach((f_data) => {
                  filterIds.push(f_data.id);
                });
                filterDetails[filter] = filterIds;
              }
              if(filter === "port_pending_id"
                && filterData['port_pending_id']
                && filterData['port_pending_id'].length
              ){
                if(typeof filterData['port_pending_oper'] !== "undefined"
                  && typeof filterData['port_pending_oper'].id !== "undefined"
                ){
                  filterDetails['port_pending_oper'] = filterData['port_pending_oper'].id;
                }else{
                  filterDetails['port_pending_oper'] = 'OR';
                }
              }else if(filter === "port_filled_id"
                && filterData['port_filled_id']
                && filterData['port_filled_id'].length
              ){
                if(typeof filterData['port_filled_oper'] !== "undefined"
                  && typeof filterData['port_filled_oper'].id !== "undefined"
                ){
                  filterDetails['port_filled_oper'] = filterData['port_filled_oper'].id;
                }else{
                  filterDetails['port_filled_oper'] = 'OR';
                }
              }
            }else if(filterFieldType[filter] === 'object'){
              console.log(filter)
              if(filter !== 'mobile_code_id'
                  && filter !== 'port_pending_oper'
                  && filter !== 'port_filled_oper'
                  && filterData[filter] 
                  && typeof filterData[filter].id !== "undefined"
              ){
                console.log('yesss')
                filterDetails[filter] = filterData[filter].id;
              }
            }else{
              if(filter === "mobile_number"
                && filterData['mobile_number']
              ){
                if(typeof filterData['mobile_code_id'] !== "undefined"
                  && typeof filterData['mobile_code_id'].id !== "undefined"
                ){
                  filterDetails['mobile_code_id'] = filterData['mobile_code_id'].id;
                }else{
                  filterDetails['mobile_code_id'] = 101;
                }
              }
              if(filterData[filter]){
                filterDetails[filter] = filterData[filter];
              }
            }
          });
        }
        return filterDetails;
      }catch(e){
        console.log(e)
        error_api_call({error: `admin|student|list|getFilterData|${e}`});
        alertResponseMessage({alert_type: 2, message: "Error Fetching Data"});
        return null;
      }
    }
    //Download Data
    const dataExport = async(event, form_type) => {
      try{
        event.preventDefault();
        setIsProcess({...isProcess, [form_type]: true});
        const filterDetails = getFilterData();
        if(filterDetails){
          const listApiData = await post_api_call('students',
                                                  {filter: 
                                                    {...filterDetails},
                                                  });
          if(listApiData.status === 200){
            // setMasterTableData(listApiData.data);
            // setIsLoading(false);
             //Download Excel
             if(listApiData.data.length === 0){
              alertResponseMessage({alert_type: 2, message: "Nothing to Export"});
              setIsProcess({...isProcess, [form_type]: false});
              return;
             }
            const filename = filenameExcel ? `${filenameExcel}.xlsx` : 'students.xlsx';
            var Heading = [];
            if(props.userRoleIds.includes(2)){
              Heading = [
                      ['User Id (For Internal Use)',
                        'Name',
                        'Email',
                        'Mobile Number',
                        'School',
                        'Class',
                        'Member Type',
                        'PORT Completion %',
                        'PORT Expiry Date (DD/MM/YYYY)',
                        'Competition Applied',
                        'PORT Filled Section',
                        'Country',
                        'State',
                        'City',
                        'Registered Date (DD/MM/YYYY)',
                        'Status'
                        // 'Branch Name'	
                      ]
                  ];
            }else{
              Heading = [
                ['User Id (For Internal Use)',
                  'Name',
                  'Email',
                  'Mobile Number',
                  'Class',
                  'Member Type',
                  'PORT Completion %',
                  'Competition Applied',
                  'PORT Filled Section',
                  'Status'
                  // 'Branch Name'	
                ]
              ];
            }
            var excelData = [];
            listApiData.data.forEach((data) => {
              var exData = {};
              exData.id = data.id;
              exData.name = `${data.first_name} ${data.last_name}`;
              exData.email = data.user_email?.email||'';
              var mobNo = '';
              if(data.user_mobile){
                mobNo = `(${data.user_mobile.phone_code}) ${data.user_mobile.mobile_number}`;
              }
              exData.mobile = mobNo;
              if(props.userRoleIds.includes(2)){
                exData.school_name = data.school_name;
              }
              exData.class_name = data.class_name;
              exData.member_type = data.member_type;
              exData.port_act_count = `${data.port_act_count}%`;
              if(props.userRoleIds.includes(2)){
                exData.expiry_date = moment(data.expiry_at).format("DD/MM/yyyy");
                // <ExpiryDays days={calculateLeftDays(data.expiry_at)} />
              }
              var compData = '';
              data.life_exp_com_details.forEach((comp_d, comp_index) => {
                compData += comp_d.label;
                if(comp_index !== data.life_exp_com_details.length - 1){
                  compData += ', ';
                }
              });
              exData.competition = compData;
              var portSecData = '';
              data.section_ids?.split(',').sort().forEach((sec_d, sec_index) => {
                portSecData += portSecTitle[sec_d];
                if(sec_index !== data.section_ids?.split(',').length - 1){
                  portSecData += ', ';
                }
              });
              exData.port_section = portSecData;
              if(props.userRoleIds.includes(2)){
                exData.country_name = data.country_name;
                exData.state = data.state;
                exData.city = data.city;
                exData.created_at = moment(data.created_at).format("DD/MM/yyyy");
              }
              exData.status = data.status;
              excelData.push(exData);                       
            });
            // var ws = XLSX.utils.json_to_sheet(dataScrape.excelData); 
            const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A2', skipHeader: true });
            var wb = XLSX.utils.book_new();
            // wb['A1'] = {
            //   fill: {
            //     patternType: 'solid',
            //     fgColor: { rgb: 'FF939393' }
            //   },
            //   font: {
            //     name: 'Times New Roman',
            //     sz: 16,
            //     color: { rgb: '#FF000000' },
            //     bold: false,
            //     italic: false,
            //     underline: false
            //   }
            // };
            XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
            XLSX.utils.book_append_sheet(wb, ws, "Students"); 
            XLSX.writeFile(wb,filename); 
          }
        }
        setIsProcess({...isProcess, [form_type]: false});
      }catch(e){
        console.log(e)
        error_api_call({error: `admin|student|list|dataExport|${e}`});
        setIsProcess({...isProcess, [form_type]: false});
        alertResponseMessage({alert_type: 2, message: "Error Exporting Data"});
      }
    }
    //Scroll
    const scrollToTable = () => {
      const section = document.querySelector('#tableMaster');
      console.log('section', section)
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    //Reset Filter Form
    const resetFilterForm = (event) => {
      try{
        event.preventDefault();
        // setFilterData({});
        setFilterData({...filterInitial});
        // setFilterData(props.userRoleIds.includes(2) ? {...filterInitial} : {...filterInitialTeach});
      }catch(e){
        alertResponseMessage({alert_type: 2, message: "Error Resetting"});
      }
    }

    return (
        <>
        <div className="row">
            {/* <div className="col-lg-12 text-end">
                <button className="btn btn-info mb-2" onClick={(e) => toggleModal(e, 'new', true, '', 'new')}>Add New Student</button>
            </div> */}
              <div className="col-lg-12">
                <div className="card p-2 mb-2">
                  {/* Filter by: */}
                  <FilterFloatMaster
                      formType="filter"
                      data={filterData}
                      // isProcess={false}
                      _isProcess={{...isProcess}}
                      // _isBtnDisabled={{...isBtnDisabled}}
                      _updateInput= {(form_type, variable_type, input_type, field, value) => updateInput(form_type, variable_type, input_type, field, value)}
                      _formData={[{label: "Filter By:", fields: [...filterTableData]}]}
                      _submitForm={(e, form_type) => fetchStudentList(e, form_type)}
                      // _formData={[{label: "Filter By:",
                      //             fields: props.userRoleIds.includes(2)//SA
                      //                     ? [...studentFilter]
                      //                     : props.userRoleIds.includes(5)//Principal
                      //                       ? [...studentFilterPrin]
                      //                       : [...studentFilterTeach]
                      //           }]}
                      _is_download={true}
                      _dataExport={(e, form_type) => dataExport(e, form_type)}
                      selectOptions={{country_id:
                                        {value: 'id',
                                        label: 'name',
                                        data: [...oppCountryOpt],
                                      },
                                      class_id: {value: 'id',
                                        label: 'class_name',
                                        data: [...oppClassOpt]},
                                      school_id: {value: 'id',
                                        label: 'school_name',
                                        data: [...oppSchoolOpt]},
                                      state_id: {value: 'id',
                                        label: 'state',
                                        data: [...oppStateOpt]},
                                      city_id: {value: 'id',
                                        label: 'city',
                                        data: [...oppCityOpt]},
                                      member_type: {value: 'id',
                                        label: 'member',
                                        data: studentTypeOpp},
                                      completion_id: {value: 'id',
                                        label: 'percentage',
                                        data: portCompleOpp},
                                      validity_id: {value: 'id',
                                        label: 'validity',
                                        data: portExpiryOpp},
                                      active_status_id: {value: 'id',
                                        label: 'status',
                                        data: activeStatusOpp},
                                      competition_id: {value: 'id',
                                        label: 'label',
                                        data: oppComptOpt},
                                      port_filled_id: {value: 'id',
                                        label: 'section',
                                        data: portSectionOpp},
                                      port_pending_id: {value: 'id',
                                        label: 'section',
                                        data: portSectionOpp},
                                      mobile_code_id: {value: 'id',
                                        label: 'phone_code',
                                        data: oppCountryOpt},
                                      port_filled_oper: {value: 'id',
                                        label: 'oper',
                                        data: filterOperator},
                                      port_pending_oper: {value: 'id',
                                        label: 'oper',
                                        data: filterOperator}
                                    }}
                      _resetFilterForm = {(e) => resetFilterForm(e)}
                      _excelSheetName = {filenameExcel}
                  />
                </div>
                {totalResult > 0 && !isLoading && (
                      <>
                      {isFilter === 1 && totalStudent > 0 && (
                        <p className="float-start m-2">Based on Filter: <b>{Math.round((totalResult*100)/totalStudent)}%</b> Students</p>
                      )}
                      <p className="float-end m-2">Total: <b>{totalResult}</b> {totalResult > 1 ? 'Students' : 'Student'}</p>
                      </>
                    )}
              </div>
              <div className="col-lg-12 grid-margin stretch-card scroll-table" id="tableMaster" ref={tableRef}>
                <div className="card">
                  <div className="card-body table-responsive p-0">
                    {/* <h4 className="card-title">Basic Table</h4> */}
                    {/* <p className="card-description"> Add class <code>.table</code></p> */}
                    <table className="table table-bordered table-active table-master">
                      <thead className="bg-gradient-secondary">
                        <tr>
                          <th>User Id</th>
                          <th className="w-200p">Name</th>
                          {/* <th>Last Name</th> */}
                          <th>Email</th>
                          <th>Mobile</th>
                          {props.userRoleIds.includes(2) && (
                            <>
                            <th>School</th>
                            <th>Branch</th>
                            </>
                          )}
                          <th>Class</th>
                          <th>Member Type</th>
                          <th>PORT Completion %</th>
                          {props.userRoleIds.includes(2) && (
                            <th>PORT Expiry Date<br/>(DD/MM/YYYY)</th>
                          )}
                          <th>Competition Applied</th>
                          <th>PORT Filled Section</th>
                          {props.userRoleIds.includes(2) && (
                            <>
                            <th>Country</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Registered Date<br/>(DD/MM/YYYY)</th>
                            </>
                          )}
                          {/* <th>Is Approved?</th> */}
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading
                        ? 
                        <tr className="text-center"><td colSpan={props.userRoleIds.includes(2) ? 17 : 11}><div className="p-4"><img alt="Loader" src={require('../../assets/images/Image-Loader.gif')} /></div></td></tr>
                        :
                          masterTableData.length > 0
                          ?
                          <>
                          {masterTableData.map((data, data_index) => (
                            <tr key={`tableData_${data_index}`}>
                              <td>{data.id}</td>
                              <td>{data.first_name} {data.last_name}</td>
                              <td>{data.user_email?.email||''}</td>
                              <td>{data.user_mobile && (
                                <span className="d-flex">
                                <span>({data.user_mobile.phone_code})</span>
                                <span>&nbsp;{data.user_mobile.mobile_number}</span>
                                </span>
                                )}</td>
                              {props.userRoleIds.includes(2) && (
                                <>
                                <td>{data.school_name}</td>
                                <td>{data.branch_name||""}</td>
                                </>
                              )}
                              <td>{data.class_name}</td>
                              <td><span className={`${data.member_type === 'GLOSCO' 
                                                      ? 'text-success'
                                                      : 'text-behance'}`}>{data.member_type}</span></td>
                              
                              <td><span className={`${data.port_act_count > 79 
                                                      ? 'text-success'
                                                      : data.port_act_count > 50 
                                                          ? 'text-warning'
                                                          : 'text-danger'}`}><b>{data.port_act_count}%</b></span></td>
                              {props.userRoleIds.includes(2) && (
                                <>
                                <td>{data.expiry_at && (
                                    <span>
                                      <span>{moment(data.expiry_at).format("DD/MM/yyyy")}</span>
                                      <br/>(<ExpiryDays days={calculateLeftDays(data.expiry_at)} />)
                                    </span>
                                  )}</td>
                                </>
                              )}
                              <td>
                                {data.life_exp_com_details.map((comp_d, comp_index) => (
                                  <span key={`lax_${comp_index}`}>
                                      {comp_d.label}
                                      {comp_index !== data.life_exp_com_details.length - 1 && (<>, &nbsp;</>)}
                                  </span>
                                ))}  
                              </td>
                              <td>
                                {/* {filledPortSecView(data.section_ids)} */}
                                <p className="alter-color scroll-td mb-0">
                                {data.section_ids?.split(',').sort().map((sec_d, sec_index) => (
                                  <span key={`lax_${sec_index}`}>
                                      {portSecTitle[sec_d]}
                                      {sec_index !== data.section_ids?.split(',').length - 1 && (<>, &nbsp;</>)}
                                  </span>
                                ))}
                                </p>
                              </td>
                              {props.userRoleIds.includes(2) && (
                                <>
                                <td>{data.country_name}</td>
                                <td>{data.state}</td>
                                <td>{data.city}</td>
                                <td>{moment(data.created_at).format("DD/MM/yyyy")} </td>
                                {/* {moment(data.created_at).tz('Asia/Kolkata').format('DD/MM/yyyy')} */}
                                </>
                              )}
                              <td><label className={`badge ${data.status === 'Active' ? 'badge-success' : 'badge-danger'}`}>{data.status}</label></td>
                              <td>
                                {(data.visibility === 1
                                  && calculateLeftDays(data.expiry_at) > 0
                                ) && (
                                  <a href={`${process.env.REACT_APP_DOMAIN}port/${data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-sm btn-twitter" title="View Data"><i className="mdi mdi-eye"></i></button></a>
                                )}
                                {/* <button onClick={(e) => loadViewModal(e, 'detail', data.id, data.role_id)} className="btn btn-sm btn-twitter" title="View Data"><i className="mdi mdi-eye"></i></button>
                                <button onClick={(e) => approveToggle(e, 'status_change', data.active_status_id, data.id, data.role_id)} className={`btn btn-sm ms-2 ${data.active_status_id === 1 ? 'btn-danger' : 'btn-success'}`} title="Activate/Inactive"><i className="mdi mdi-check-outline"></i></button>
                                <button onClick={(e) => loadViewModal(e, 'edit', data.id, data.role_id)} className={`btn btn-sm ms-2 btn-inverse-info`} title="Edit Data"><i className="mdi mdi-pencil"></i></button> */}
                              </td>
                            </tr>
                          ))}
                          </>
                          :
                          <tr className="text-center">
                            <td colSpan={props.userRoleIds.includes(2) ? 17 : 11}>
                            <div className="no-fellow mt-4">
                              <img src={require('../../assets/images/oops.png')} alt="No Matching Student Found" />
                              <p className="pt-5 head">Sorry, no matching Student found!</p>
                              <p className="sub-title ml-2 mr-2">Please try with different filter</p>
                            </div>
                            </td>
                          </tr>
                          }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>
        {/* New Entry */}
        <ModalMaster
            isShow={modalToggle.new}
            _toggleModal={(e, state) => toggleModal(e, 'new', state)}
            modKey={activeEditForm}//new, edit
            formType={'new'}
            data={formNewData}
            title={masterTitle[activeEditForm]}
            _errorData={{...errorData}}
            _isProcess={{...isProcess}}
            // _isBtnDisabled={{...isBtnDisabled}}
            _updateInput= {(form_type, variable_type, input_type, field, value) => updateInput(form_type, variable_type, input_type, field, value)}
            _submitForm={(e, form_type) => updateStake(e, form_type)}
            _formData={[...masterFieldData]}
            selectOptions={{role_id:
                              {value: 'id',
                              label: 'role',
                              data: [...oppCountryOpt],
                            },
                            class_id: {value: 'id',
                              label: 'class_name',
                              data: [...oppClassOpt]},
                            school_id: {value: 'id',
                              label: 'school_name',
                              data: [...oppSchoolOpt]},
                          }}
            // doc_url={docUrlData}
        />
        {/* View Oppo Details */}
        <ModalMasterDetail
            data={detailedOppData}
            isShow={modalToggle.detail}
            _toggleModal={(e, state) => toggleModal(e, 'detail', state)}
            loader={modalLoader.detail}
            _formData={[{label: "Detail", fields: [...formViewData]}]}
            title={"Student Details"}
        />
        {/* Edit Details */}
        <ModalMaster
            isShow={modalToggle.edit}
            loader={modalLoader.edit}
            _toggleModal={(e, state) => toggleModal(e, 'edit', state)}
            modKey={activeEditForm}//edit, edit
            formType={'edit'}
            data={formEditData}
            title={masterTitle[activeEditForm]}
            _errorData={{...errorData}}
            _isProcess={{...isProcess}}
            // _isBtnDisabled={{...isBtnDisabled}}
            _updateInput= {(form_type, variable_type, input_type, field, value) => updateInput(form_type, variable_type, input_type, field, value)}
            _submitForm={(e, form_type) => updateStake(e, form_type)}
            _formData={[...masterFieldData]}
            selectOptions={{
                            // school_id:
                            //   {value: 'id',
                            //   label: 'school_name',
                            //   data: [...oppSchoolOpt],
                            // },
                            class_id: {value: 'id',
                              label: 'class_name',
                              data: [...oppClassOpt]},
                          }}
            // doc_url={docUrlData}
        />
        </>
    )    
}

export default StudentList;